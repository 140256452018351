export default {
  Canvas: {
    CanvasWidth: 0,
    CanvasHeight: 0,
    CanvasBorderWeight: 1,
    CanvasBorderColor: '#E0E0DF',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 0,
    CanvasChartHeight: 0,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },
  
  DataItems: [
    { AREA: 'HULL',    DISC: 'Bypassing Safety Controls', IS_WK: 49, },
    { AREA: 'HULL',    DISC: 'Confined Space', IS_WK: 45, },
    { AREA: 'HULL',    DISC: 'Driving', IS_WK: 69, },
    { AREA: 'HULL',    DISC: 'Energy Isolation', IS_WK: 58, },
    { AREA: 'HULL',    DISC: 'Hot Works', IS_WK: 75, },
    { AREA: 'HULL',    DISC: 'Line of Fire', IS_WK: 48, },
    { AREA: 'HULL',    DISC: 'Safe Mechanical Lifting', IS_WK: 77, },
    { AREA: 'HULL',    DISC: 'Work Authorisation', IS_WK: 85, },
    { AREA: 'HULL',    DISC: 'Working at Height', IS_WK: 85, },

    { AREA: 'LQ',      DISC: 'Bypassing Safety Controls', IS_WK: 27, },
    { AREA: 'LQ',      DISC: 'Confined Space', IS_WK: 80, },
    { AREA: 'LQ',      DISC: 'Driving', IS_WK: 74, },
    { AREA: 'LQ',      DISC: 'Energy Isolation', IS_WK: 65, },
    { AREA: 'LQ',      DISC: 'Hot Works', IS_WK: 28, },
    { AREA: 'LQ',      DISC: 'Line of Fire', IS_WK: 87, },
    { AREA: 'LQ',      DISC: 'Safe Mechanical Lifting', IS_WK: 62, },
    { AREA: 'LQ',      DISC: 'Work Authorisation', IS_WK: 55, },
    { AREA: 'LQ',      DISC: 'Working at Height', IS_WK: 55, },

    { AREA: 'TOPSIDE', DISC: 'Bypassing Safety Controls', IS_WK: 65, },
    { AREA: 'TOPSIDE', DISC: 'Confined Space', IS_WK: 7, },
    { AREA: 'TOPSIDE', DISC: 'Driving', IS_WK: 7, },
    { AREA: 'TOPSIDE', DISC: 'Energy Isolation', IS_WK: 7, },
    { AREA: 'TOPSIDE', DISC: 'Hot Works', IS_WK: 71, },
    { AREA: 'TOPSIDE', DISC: 'Line of Fire', IS_WK: 30, },
    { AREA: 'TOPSIDE', DISC: 'Safe Mechanical Lifting', IS_WK: 63, },
    { AREA: 'TOPSIDE', DISC: 'Work Authorisation', IS_WK: 29, },
    { AREA: 'TOPSIDE', DISC: 'Working at Height', IS_WK: 29, },
  ],


  Queries: {
    SQL1: [
      {},
    ],
  },

  JQueries: {
    title: {
      main: {visible: 'Y', x: 50, y: 10, tSize: 15, tColor: '#333333', name: 'TITLE'},
      sub:  {visible: 'Y', x: 95, y: 13, tSize: 10, tColor: '#bcbcbc', name: 'Progress'},
    },

    timeline: {
      axis: null,
      xItems: [],
      tSize: 10,
      tColor: '#000',
      itemY: 2
    },

    primary: {axis: null, max: null, padding: 25, tSize: 11, tColor: '#333', unit: '' , title: 'Number of Issued OBS'},

    chart: {
      x: 100,
      y: 50,
      width: 550,
      height: 200,
      barSize: 30,
      stackTotal: {visible: 'Y', tSize: 12, tColor: '#44A9DF'},

      code: {
        item:  'DISC',
        bar:   'AREA',
        value: 'IS_WK',
      },
      
      styles: [
        {name: 'Hull',    value: 'HULL',    bColor: '#44A9DF', vSize: 9, vColor: '#757575', filter:'F1', opacity: 1},
        {name: 'LQ',      value: 'LQ',      bColor: '#83D2F5', vSize: 9, vColor: '#757575', filter:'F2', opacity: 1},
        {name: 'Topside', value: 'TOPSIDE', bColor: '#B4E3FA', vSize: 9, vColor: '#757575', filter:'F3', opacity: 1},
      ]
    },

    legends: {
      visible: 'Y',
      y: 38,
      align: 'left',
      tSize: 10, 
      tColor: '#333',
    },

    filter: {
      F1 : " 1=1 ",
      F2 : " 2=2 ",
      F3 : " 3=3 ",
    }
  },

}