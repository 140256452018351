
export default {
  Canvas: {
    CanvasWidth: 700,
    CanvasHeight: 700,
    CanvasBorderWeight: .5,
    CanvasBorderColor: '#E0E0DF',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 300,
    CanvasChartHeight: 300,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
    { AREA: 'LQ',        DISC: 'Structure',  TOTAL: 847,  PS_CUM: 156, PF_CUM: 29, AS_CUM: 157, AF_CUM: 32,  DS: 1,  DF: 3,  OS: 0, OF: 0, PLAN: 7.9,  ACT: 8.2,  DEV: -0.3, },
    { AREA: 'LQ',        DISC: 'Mechanical',  TOTAL: 847,  PS_CUM: 156, PF_CUM: 29, AS_CUM: 157, AF_CUM: 32,  DS: 1,  DF: 3,  OS: 0, OF: 0, PLAN: 7.9,  ACT: 8.2,  DEV: -0.3, },
    { AREA: 'LQ',        DISC: 'Piping',  TOTAL: 847,  PS_CUM: 156, PF_CUM: 29, AS_CUM: 157, AF_CUM: 32,  DS: 1,  DF: 3,  OS: 0, OF: 0, PLAN: 7.9,  ACT: 8.2,  DEV: -0.3, },
    { AREA: 'LQ',        DISC: 'Painting',  TOTAL: 847,  PS_CUM: 156, PF_CUM: 29, AS_CUM: 157, AF_CUM: 32,  DS: 1,  DF: 3,  OS: 0, OF: 0, PLAN: 7.9,  ACT: 8.2,  DEV: -0.3, },
    { AREA: 'Topside',   DISC: 'Structure',  TOTAL: 3729, PS_CUM: 10,  PF_CUM: 0,  AS_CUM: 10,  AF_CUM: 0,   DS: 0,  DF: 0,  OS: 0, OF: 0, PLAN: 0.1,  ACT: 0.1,  DEV: 0, },
    { AREA: 'Topside',   DISC: 'Mechanical',  TOTAL: 3729, PS_CUM: 10,  PF_CUM: 0,  AS_CUM: 10,  AF_CUM: 0,   DS: 0,  DF: 0,  OS: 0, OF: 0, PLAN: 0.1,  ACT: 0.1,  DEV: 0, },
    { AREA: 'Topside',   DISC: 'Piping',  TOTAL: 3729, PS_CUM: 10,  PF_CUM: 0,  AS_CUM: 10,  AF_CUM: 0,   DS: 0,  DF: 0,  OS: 0, OF: 0, PLAN: 0.1,  ACT: 0.1,  DEV: 0, },
    { AREA: 'Topside',   DISC: 'Painting',  TOTAL: 3729, PS_CUM: 10,  PF_CUM: 0,  AS_CUM: 10,  AF_CUM: 0,   DS: 0,  DF: 0,  OS: 0, OF: 0, PLAN: 0.1,  ACT: 0.1,  DEV: 0, },
    { AREA: 'Hull',      DISC: 'Structure',  TOTAL: 3005, PS_CUM: 156, PF_CUM: 49, AS_CUM: 188, AF_CUM: 72,  DS: 32, DF: 23, OS: 0, OF: 3, PLAN: 5.3,  ACT: 5.6,  DEV: -0.3, },
    { AREA: 'Hull',      DISC: 'Mechanical',  TOTAL: 3005, PS_CUM: 156, PF_CUM: 49, AS_CUM: 188, AF_CUM: 72,  DS: 32, DF: 23, OS: 0, OF: 3, PLAN: 5.3,  ACT: 5.6,  DEV: -0.3, },
    { AREA: 'Hull',      DISC: 'Piping',  TOTAL: 3005, PS_CUM: 156, PF_CUM: 49, AS_CUM: 188, AF_CUM: 72,  DS: 32, DF: 23, OS: 0, OF: 3, PLAN: 5.3,  ACT: 5.6,  DEV: -0.3, },
    { AREA: 'Hull',      DISC: 'Painting',  TOTAL: 3005, PS_CUM: 156, PF_CUM: 49, AS_CUM: 188, AF_CUM: 72,  DS: 32, DF: 23, OS: 0, OF: 3, PLAN: 5.3,  ACT: 5.6,  DEV: -0.3, },
    { AREA: 'OVERALL',   DISC: 'OVERALL',  TOTAL: 7581, PS_CUM: 322, PF_CUM: 78, AS_CUM: 355, AF_CUM: 104, DS: 33, DF: 26, OS: 0, OF: 3, PLAN: 1.7,  ACT: 1.8,  DEV: -0.1, },
  ],

  Queries: {
    SQL1: [
      {title: 'Area', ref: 'AREA', group: '', width: 80, type: 'TEXT', format: '', tColor: '#757575', align: 'start', overlap: 'N', zero: 'Y', lastOne: 'N', reqest: '', filter: ''},
      {title: 'Disc.', ref: 'DISC', group: '', width: 80, type: 'TEXT', format: '', tColor: '#757575', align: 'start', overlap: 'Y', zero: 'Y', lastOne: 'N', reqest: '', filter: ''},
      {title: 'Total', ref: 'TOTAL', group: '', width: 40, type: 'LINK', format: '$', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', reqest: '', filter: ''},
      {title: 'Start', ref: 'PS_CUM', group: 'Plan', width: 40, type: 'LINK', format: '$', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', reqest: '', filter: ''},
      {title: 'Issued', ref: 'PF_CUM', group: 'Plan', width: 40, type: 'LINK', format: '$', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', reqest: '', filter: ''},
      {title: 'Start', ref: 'AS_CUM', group: 'Actual', width: 40, type: 'LINK', format: '$', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', reqest: '', filter: ''},
      {title: 'Issued', ref: 'AF_CUM', group: 'Actual', width: 40, type: 'LINK', format: '$', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', reqest: '', filter: ''},
      {title: 'Start', ref: 'OS', group: 'Overdue', width: 40, type: 'LINK', format: '$', tColor: '#f370ac', align: 'end', overlap: 'Y', zero: 'N', lastOne: 'Y', reqest: '', filter: ''},
      {title: 'Issued', ref: 'OF', group: 'Overdue', width: 40, type: 'LINK', format: '$', tColor: '#f370ac', align: 'end', overlap: 'Y', zero: 'N', lastOne: 'Y', reqest: '', filter: ''},
      {title: 'Plan', ref: 'PLAN', group: 'Progress', width: 40, type: 'LINK', format: '%', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', reqest: '', filter: ''},
      {title: 'Actual', ref: 'ACT', group: 'Progress', width: 80, type: 'BAR', format: '%', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', reqest: '', filter: ''},
      {title: 'Delta', ref: 'DEV', group: 'Progress', width: 40, type: 'DELTA', format: '', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', reqest: '', filter: ''},
    ],

    SQL2: [
      {ref: 'AREA', jMap: 'KEY1'},
    ],
  },

  JQueries: {
    JSON1: [
      {title: 'Area', ref: 'AREA', group: '', width: 80, type: 'TEXT', format: '', tColor: '#757575', align: 'start', overlap: 'N', zero: 'Y', lastOne: 'N', reqest: '', filter: ''},
      {title: 'Disc.', ref: 'DISC', group: '', width: 80, type: 'TEXT', format: '', tColor: '#757575', align: 'start', overlap: 'Y', zero: 'Y', lastOne: 'N', reqest: '', filter: ''},
      {title: 'Total', ref: 'TOTAL', group: '', width: 40, type: 'LINK', format: '$', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', reqest: '', filter: ''},
      {title: 'Start', ref: 'PS_CUM', group: 'Plan', width: 40, type: 'LINK', format: '$', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', reqest: '', filter: ''},
      {title: 'Issued', ref: 'PF_CUM', group: 'Plan', width: 40, type: 'LINK', format: '$', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', reqest: '', filter: ''},
      {title: 'Start', ref: 'AS_CUM', group: 'Actual', width: 40, type: 'LINK', format: '$', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', reqest: '', filter: ''},
      {title: 'Issued', ref: 'AF_CUM', group: 'Actual', width: 40, type: 'LINK', format: '$', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', reqest: '', filter: ''},
      {title: 'Start', ref: 'OS', group: 'Overdue', width: 40, type: 'LINK', format: '$', tColor: '#f370ac', align: 'end', overlap: 'Y', zero: 'N', lastOne: 'Y', reqest: '', filter: ''},
      {title: 'Issued', ref: 'OF', group: 'Overdue', width: 40, type: 'LINK', format: '$', tColor: '#f370ac', align: 'end', overlap: 'Y', zero: 'N', lastOne: 'Y', reqest: '', filter: ''},
      {title: 'Plan', ref: 'PLAN', group: 'Progress', width: 40, type: 'LINK', format: '%', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', reqest: '', filter: ''},
      {title: 'Actual', ref: 'ACT', group: 'Progress', width: 80, type: 'BAR', format: '%', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', reqest: '', filter: ''},
      {title: 'Delta', ref: 'DEV', group: 'Progress', width: 40, type: 'DELTA', format: '', tColor: '#757575', align: 'end', overlap: 'Y', zero: 'Y', lastOne: 'Y', reqest: '', filter: ''},
    ],

    JSON2: [
      {ref: 'AREA', jMap: 'KEY1'},
    ],
  },
}