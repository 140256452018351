export default {
  Canvas: {
    CanvasWidth: 1400,
    CanvasHeight: 600, //previous value : 570
    CanvasBorderWeight: .5,
    CanvasBorderColor: 'Transparent',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 400,
    CanvasChartHeight: 200,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: 'Transparent',
    CanvasChartFillColor: 'Transparent',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
  },
  
  Note: {
  },

  
  DataItems: [
    {PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'Exmar',        TOTAL: 5228.3,   P_WK: 163.4,  A_WK: 243.5,  D_WK: 80,  P_PRO: 30, P_CUM: 4914.3,  A_CUM: 4917.5,  D_CUM: 3, },
    {PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'HHI',          TOTAL: 9460.6,   P_WK: 633.3,  A_WK: 788.9,  D_WK: 156, P_PRO: 30, P_CUM: 7557.9,  A_CUM: 7664.2,  D_CUM: 106, },
    {PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'WOOD',         TOTAL: 17673.2,  P_WK: 1094.2, A_WK: 1269.2, D_WK: 175, P_PRO: 30, P_CUM: 14100.7, A_CUM: 14731.8, D_CUM: 631, },
    {PHASE: 'Engineering',   GROUP: 'Detail Eng',     SUBCONT: 'OVERALL',      TOTAL: 32362.2,  P_WK: 1890.9, A_WK: 2301.6, D_WK: 411, P_PRO: 30, P_CUM: 26572.9, A_CUM: 27313.5, D_CUM: 741, },
    {PHASE: 'Engineering',   GROUP: 'Shop Drawing',   SUBCONT: 'HHI',          TOTAL: 12252.5,  P_WK: 246.7,  A_WK: 192.1,  D_WK: -55, P_PRO: 30, P_CUM: 349.7,   A_CUM: 295.1,   D_CUM: -55, },
    {PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'HHI',          TOTAL: 185,      P_WK: 10,     A_WK: 5.3,    D_WK: -5,  P_PRO: 30, P_CUM: 34,      A_CUM: 24.5,    D_CUM: -10, },
    {PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'Gate',         TOTAL: 551,      P_WK: 15,     A_WK: 9.3,    D_WK: -6,  P_PRO: 30, P_CUM: 80,      A_CUM: 71.1,    D_CUM: -9, },
    {PHASE: 'Engineering',   GROUP: 'Commissing Eng', SUBCONT: 'OVERALL',      TOTAL: 736,      P_WK: 25,     A_WK: 14.6,   D_WK: -10, P_PRO: 30, P_CUM: 114,     A_CUM: 95.6,    D_CUM: -18, },
    {PHASE: 'Engineering',   GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 45350.7,  P_WK: 2162.6, A_WK: 2508.3, D_WK: 346, P_PRO: 30, P_CUM: 27036.6, A_CUM: 27704.2, D_CUM: 668, },
    {PHASE: 'Construction',  GROUP: 'HHI',            SUBCONT: 'HHI',          TOTAL: 68489.9,  P_WK: 199.1,  A_WK: 133.5,  D_WK: -66, P_PRO: 30, P_CUM: 199.1,   A_CUM: 133.5,   D_CUM: -66, },
    {PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'ShinHwaTech.', TOTAL: 0,        P_WK: 0,      A_WK: 140,    D_WK: 140, P_PRO: 30, P_CUM: 0,       A_CUM: 140,     D_CUM: 140, },
    {PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'Sung-II',      TOTAL: 0,        P_WK: 0,      A_WK: 67,     D_WK: 67,  P_PRO: 30, P_CUM: 0,       A_CUM: 67,      D_CUM: 67, },
    {PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'COSCO',        TOTAL: 29533.2,  P_WK: 106,    A_WK: 225.1,  D_WK: 119, P_PRO: 30, P_CUM: 106,     A_CUM: 225.1,   D_CUM: 119, },
    {PHASE: 'Construction',  GROUP: 'Subcontractor',  SUBCONT: 'OVERALL',      TOTAL: 29533.2,  P_WK: 106,    A_WK: 432.1,  D_WK: 326, P_PRO: 30, P_CUM: 106,     A_CUM: 432.1,   D_CUM: 326, },
    {PHASE: 'Construction',  GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 98023.1,  P_WK: 305.1,  A_WK: 565.6,  D_WK: 261, P_PRO: 30, P_CUM: 305.1,   A_CUM: 565.6,   D_CUM: 261, },
    {PHASE: 'Commissioning', GROUP: 'Commissioning',  SUBCONT: 'HHI',          TOTAL: 3199.9,   P_WK: 0,      A_WK: 0,      D_WK: 0,   P_PRO: 30, P_CUM: 0,       A_CUM: 0,       D_CUM: 0, },
    {PHASE: 'Management',    GROUP: 'Management',     SUBCONT: 'HHI',          TOTAL: 7171,     P_WK: 200,    A_WK: 250,    D_WK: 50,  P_PRO: 30, P_CUM: 2267,    A_CUM: 2637,    D_CUM: 370, },
    {PHASE: 'OVERALL',       GROUP: 'OVERALL',        SUBCONT: 'OVERALL',      TOTAL: 153744.8, P_WK: 2667.7, A_WK: 3323.9, D_WK: 656, P_PRO: 30, P_CUM: 29608.7, A_CUM: 30906.8, D_CUM: 1298, },
  ],


  Queries: {
    SQL1: [
      {}
    ],
  },


  JQueries: {
    options: {
      TITLE:          'Monthly Manpower Allocation Status by Resource',
      TITLE_VISIBLE:  'Y',
      TITLE_SIZE:     13,

      HEADER_GROUP:   2,
      DATA_GROUP:     3,

      HEADER_HEIGHT:  '0-25-25-15',
      LINE_HEIGHT:    20,
      TEXT_SIZE:      '11-11-10-9',
      TEXT_COLOR:     '#000000-#333333-#757575-#bcbcbc',
      STROKE_COLOR:   '#000000-#333333-#757575-#bcbcbc',
      ZERO_IGNORE:    'Y'
    },

    headers: [
      {GROUP: 'TH',  NAME: 'This Week',   ROW: 1, ROWSPAN: 1, },
      {GROUP: 'CM',  NAME: 'Cumulative.', ROW: 1, ROWSPAN: 1, },
    ],

    columns: [ 
      {G1: null, COL: 1,  ROW: 1, ROWSPAN: 2, NAME: 'AREA',           WRAP: 0, CODE: 'PHASE',   WIDTH: 80,  ALIGN: 'start', TYPE: null,   BAR: null,      FILTER: null, },
      {G1: null, COL: 2,  ROW: 1, ROWSPAN: 2, NAME: 'ZONE',           WRAP: 0, CODE: 'GROUP',   WIDTH: 110, ALIGN: 'start', TYPE: null,   BAR: null,      FILTER: null, },
      {G1: null, COL: 3,  ROW: 1, ROWSPAN: 2, NAME: 'YARD',           WRAP: 0, CODE: 'SUBCONT', WIDTH: 80,  ALIGN: 'start', TYPE: null,   BAR: null,      FILTER: null, },
      {G1: null, COL: 4,  ROW: 1, ROWSPAN: 2, NAME: 'Total',          WRAP: 0, CODE: 'TOTAL',   WIDTH: 60,  ALIGN: 'end',   TYPE: null,   BAR: null,      FILTER: 'F1', },
      {G1: 'TH', COL: 5,  ROW: 2, ROWSPAN: 1, NAME: 'Plan vs Actual', WRAP: 13, CODE: 'P_WK',    WIDTH: 60,  ALIGN: 'end',   TYPE: null,   BAR: null,      FILTER: 'F2', },
      {G1: 'TH', COL: 6,  ROW: 2, ROWSPAN: 1, NAME: 'Actual',         WRAP: 0, CODE: 'A_WK',    WIDTH: 60,  ALIGN: 'end',   TYPE: null,   BAR: null,      FILTER: 'F3', },
      {G1: 'TH', COL: 7,  ROW: 2, ROWSPAN: 1, NAME: 'Delta',          WRAP: 0, CODE: 'D_WK',    WIDTH: 60,  ALIGN: 'end',   TYPE: 'DEV',  BAR: null,      FILTER: null, },
      {G1: 'CM', COL: 8,  ROW: 2, ROWSPAN: 1, NAME: 'Progress',       WRAP: 0, CODE: 'P_PRO',   WIDTH: 105, ALIGN: 'end',   TYPE: 'PROG', BAR: '#F7BACF', FILTER: null, },
      {G1: 'CM', COL: 8,  ROW: 2, ROWSPAN: 1, NAME: 'Plan',           WRAP: 0, CODE: 'P_CUM',   WIDTH: 60,  ALIGN: 'end',   TYPE: null,   BAR: null,      FILTER: null, },
      {G1: 'CM', COL: 9,  ROW: 2, ROWSPAN: 1, NAME: 'Actual',         WRAP: 0, CODE: 'A_CUM',   WIDTH: 60,  ALIGN: 'end',   TYPE: null,   BAR: null,      FILTER: 'F5', },
      {G1: 'CM', COL: 10, ROW: 2, ROWSPAN: 1, NAME: 'Delta',          WRAP: 0, CODE: 'D_CUM',   WIDTH: 60,  ALIGN: 'end',   TYPE: null,   BAR: null,      FILTER: 'F6', }, 
    ],

    filter: [ 
      {
        F1 : " 1=1 ",
        F2 : " 2=2 ",
        F3 : " 3=3 ",
      },
    ],
  },

}
                                                                                                                   